import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const getHomeImage = graphql`
  query {
    homeImage: contentfulHomeDoor {
      copy
      author
      coverImage {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const HomeDoor = () => {
  const response = useStaticQuery(getHomeImage)

  return (
    <HomeDoorWrapper>
      <Link to="/works/">
        <HomeDoorContainer>
          <Banner>
            <Copy>
              Chill
              <span>&</span>
              Off
            </Copy>
            <Author>Mitsunori Nakata</Author>
          </Banner>
          <HomeImage fluid={response.homeImage.coverImage.fluid} />
        </HomeDoorContainer>
      </Link>
    </HomeDoorWrapper>
  )
}

const HomeDoorWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const HomeDoorContainer = styled.div`
  width: 100vw;
  height: 100vw;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
  position: relative;
  background-size: cover;
`

const Banner = styled.div`
  position: absolute;
  width: 100%;
  height: 20%;
  margin: 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  background-color: whitesmoke;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Mukta", sans-serif;
`

const Copy = styled.h1`
  display: block;
  margin: 1vh 0 0 0;
  font-size: 10vw;
  font-weight: 700;
  height: 80%;
  span {
    font-size: 6vw;
  }
  @media screen and (min-width: 700px) {
    font-size: 3rem;
    span {
      font-size: 2rem;
    }
  }
`

const Author = styled.h3`
  display: block;
  height: 20%;
  margin-top: -0.5vh;
  margin-bottom: 1.5vh;
  font-size: 3vw;
  font-weight: 400;
  @media screen and (min-width: 700px) {
    font-size: 1rem;
  }
`

const HomeImage = styled(Img)`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
`

export default HomeDoor
