import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

import HomeDoor from "../components/HomeDoor"

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Home" />
      <HomeDoor />
    </Layout>
  )
}

export default IndexPage
